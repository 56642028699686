/**
 * Created by jerry on 2020/03/5.
 * 时效卡api
 */
import * as API from '@/api/index'

export default {
    // 时效卡列表
    getTimeCard: params => {
        return API.POST('api/timeCard/list', params)
    },
    // 获取时效卡销售范围
    getTimeCardSaleEntity: params => {
        return API.POST('api/timeCard/timeCardSaleEntity', params)
    },
    // 获取时效卡消耗范围
    getTimeCardConsumeEntity: params => {
        return API.POST('api/timeCard/timeCardConsumeEntity', params)
    },
    // 获取时效卡适用项目
    getTimeCardProject: params => {
        return API.POST('api/timeCard/timeCardProject', params)
    },
    //创建时效卡
    createTimeCard: params => {
        return API.POST('api/timeCard/create', params)
    },
    //更新时效卡
    updateTimeCard: params => {
        return API.POST('api/timeCard/update', params)
    },
    //更新时效卡
    getTimeCardDetails: params => {
      return API.POST('api/timeCard/getTimeCardDetails', params)
    }
}